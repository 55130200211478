
import {routerPath, routerPathA} from '../tool';
import Layout from '@/views/layout/Layout.vue'

export default {
    path: '/policyManage',
    name: 'policyManage',
    component: Layout,
    meta: {
        title: '政策管理',
        hasIndex: true,
		tabView: true
    },
    children: [
        routerPathA('policyManage', 'ratePolicy', '费率政策',false),
        routerPathA('policyManage', 'ratePolicyInsert', '政策新增'),
        routerPathA('policyManage', 'ratePolicyUpdate', '政策修改'),
        routerPathA('policyManage', 'acticityPolicyQuery', '活动政策管理',false),
        routerPathA('policyManage', 'acticityPolicyInsert', '活动政策新增'),
        routerPathA('policyManage', 'acticityPolicyDetail', '活动政策详情'),
        routerPathA('policyManage', 'acticityPolicyUpdate', '活动政策修改'),
        routerPathA('policyManage', 'ratePolicyDetail', '政策详情'),
        routerPathA('policyManage', 'terminalActivityQuery', '机具活动查询',false),
        routerPathA('policyManage', 'merchantActivityQuery', '商户活动查询',false),
    ]
};