import Api from "@/api/apiAxios";
export default {
	getAgentlist: (pageNo: Number, pageSize: Number, params: object) =>
		Api.postRequest(`/agentInfo/listAgent/${pageNo}/${pageSize}`, params), //查询代理商
	getOrglist: (pageNo: Number, pageSize: Number, params: object) =>
		Api.postRequest(`/agentInfo/listOrg/${pageNo}/${pageSize}`, params), //查询机构
	getAgentLevel: (agentNo: Number) =>
		Api.postRequest(`/agentInfo/listAgentLevel/${agentNo}`), //查看代理商层级
	resetPassword: (agentNo: Number) =>
		Api.postRequest(`/agentInfo/resetPassword/${agentNo}`), //重置密码
	saveOneAgent: (params: object) =>
		Api.putRequest(`/agentInfo/saveOneAgent`, params), //新增一级代理商
	saveOrg: (params: object) => Api.putRequest(`/agentInfo/saveOrg`, params), //新增机构
	listUpperOrg: (pageNo: Number, pageSize: Number, params: object) =>
		Api.postRequest(
			`/agentInfo/listUpperOrg/${pageNo}/${pageSize}`,
			params
		), //查询一级二级机构
	beforeSaveOneAgent: (agentNo: Number) =>
		Api.getRequest(`/agentInfo/beforeSaveOneAgent/${agentNo}`), //新增代理商之前获取政策配置
	beforeSaveOrg: (parentAgentNo: Number) =>
		Api.getRequest(`/agentInfo/beforeSaveOrg/${parentAgentNo}`), //新增机构之前获取政策配置
	agentDetail: (agentNo: Number) =>
		Api.getRequest(`/agentInfo/detail/${agentNo}`), //详情
	improveAgentInfo: (agentNo: Number, params: object) =>
		Api.postRequest(`/agentInfo/improveAgentInfo/${agentNo}`, params), //代理商补充资料
	updateAgentCashBack: (agentNo: Number, params: object) =>
		Api.postRequest(`/agentInfo/updateAgentCashBack/${agentNo}`, params), //修改代理返现
	updateAgentBaseInfo: (agentNo: Number, params: object) =>
		Api.postRequest(`/agentInfo/updateAgentBaseInfo/${agentNo}`, params), //修改代理商信息
	investmentManager: (params: object) =>
		Api.putRequest(`/agentInfo/investmentManager/update`, params), //修改招商信息
	updateAgentCost: (agentNo: Number, params: object) =>
		Api.postRequest(`/agentInfo/updateAgentCost/${agentNo}`, params), //修改代理成本
	beforeUpdateAgentCost: (agentNo: Number) =>
		Api.getRequest(`/agentInfo/beforeUpdateAgentCost/${agentNo}`), //修改成本前
	beforeUpdateAgentActivity: (agentNo: Number) =>
		Api.getRequest(`/agentInfo/beforeUpdateAgentActivity/${agentNo}`), //修改返现前
	queryAgentNameList: (agentNo: Number) =>
		Api.getRequest(`/agentInfo/queryAgentNameList/${agentNo}`), //获取代理商列表
	queryAllAgentNameList: (agentNo: Number) =>
		Api.getRequest(`/agentInfo/queryAllAgentNameList?agentNoOrUserName=${agentNo}`), //获取代理商列表
	bankList: (params: object) => Api.postRequest(`/bank/list/1/100`, params), //获取银行列表
	branchList: (params: object) =>
		Api.postRequest(`/branch/list/1/100`, params), //获取支行
	agentReviewRejection: (agentNo: Number, params: object) =>
		Api.postRequest(`/agentInfo/agentReviewRejection/${agentNo}`, params) //审核退回
};
