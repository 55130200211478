import {routerPath, routerPathA} from '../tool';
import Layout from '@/views/layout/Layout.vue'

export default {
    path: '/terminalManage',
    name: 'terminalManage',
    component: Layout,
    meta: {
        title: '机具管理',
        hasIndex: true,
		tabView: true
    },
    children: [
        routerPathA('terminalManage', 'terminalQuery', '机具查询',false),
        routerPathA('terminalManage', 'hardwareQuery', '硬件管理',false),
        routerPathA('terminalManage', 'terminalUpdate', '机具修改'),
        routerPathA('terminalManage', 'terminalInsert', '机具入库'),
        routerPathA('terminalManage', 'terminalBatchUpdate', '机具批量修改'),
        routerPathA('terminalManage', 'terminalDelete', '机具批量删除'),
        routerPathA('terminalManage', 'reworkMapping', '返修映射',false),
    ]
};