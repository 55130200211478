import { render, staticRenderFns } from "./SideBar.vue?vue&type=template&id=501822cc&scoped=true"
import script from "./SideBar.vue?vue&type=script&lang=js"
export * from "./SideBar.vue?vue&type=script&lang=js"
import style0 from "./SideBar.vue?vue&type=style&index=0&id=501822cc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "501822cc",
  null
  
)

export default component.exports