import Api from "@/api/apiAxios";
export default {
	queryPage: (pageNo:Number,pageSize:Number,params:Object) => Api.postRequest(`/transOrder/queryPage/${pageNo}/${pageSize}`,params),
	hideDataQueryPage: (pageNo:Number,pageSize:Number,params:Object) => Api.postRequest(`/hideData/queryPage/${pageNo}/${pageSize}`,params),
	queryByTransOrderNo:(transOrderNo:String) => Api.getRequest(`/transProfitDetail/queryByTransOrderNo?transOrderNo=${transOrderNo}`),
	fallback: (orderNo:Number) => Api.postRequest(`/hideData/fallback/${orderNo}`),
	transOrder:{
		export:(params:object)=> Api.postRequest(`/transOrder/export`, params), 
	},
	hidaData:{
		export:(params:object)=> Api.postRequest(`/hideData/export`, params),  
	}
};