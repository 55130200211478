/**
 * @author kco1989
 * @email kco1989@qq.com
 * @date 2019-11-30 13:59
 */
import {routerPathA} from '../tool';
import Layout from '@/views/layout/Layout.vue'

export default {
    path: '/userAuthority',
    name: 'userAuthority',
    component: Layout,
    meta: {
        title: 'MENU_TREE.USER_AUTHORITY',
        hasIndex: true,
		tabView: true
    },
    children: [
        routerPathA('userAuthority', 'index', 'MENU_TREE.USER_AUTHORITY'),
        routerPathA('userAuthority', 'userManage', 'MENU_TREE.USER_MANAGE'),
        routerPathA('userAuthority', 'roleManage', 'MENU_TREE.ROLE_MANAGE'),
        routerPathA('userAuthority', 'authorityManage', 'MENU_TREE.AUTHORITY_MANAGE'),
        routerPathA('userAuthority', 'menuManage', 'MENU_TREE.MENU_MANAGE'),
        routerPathA('userAuthority', 'operLogManage', 'MENU_TREE.OPER_LOG_MANAGE'),
		routerPathA('userAuthority', 'loginInfoManage', 'MENU_TREE.LOGIN_INFO_MANAGE'),
		routerPathA('userAuthority', 'noticeManage', '公告管理'),
		routerPathA('userAuthority', 'updatePassword', '修改密码'),
		{
			path: `/userAuthority/userRoleManage/:userId`,
			meta: {
				title: 'MENU_TREE.USER_ASSIGNED_ROLE',
				tabView: true
			},
			component: () => import(`@/views/userAuthority/userRoleManage/Index.vue`)
		},
		{
			path: `/userAuthority/roleMenuManage/:roleId`,
			meta: {
				title: 'MENU_TREE.USER_ASSIGNED_AUTHORITY',
				tabView: true
			},
			component: () => import(`@/views/userAuthority/roleMenuManage/Index.vue`)
		}
    ]
};
