import Api from "@/api/apiAxios";
export default {
	// 分页查询提现订单
	queryPage: (pageNo: Number, pageSize: Number, params: Object) =>
		Api.postRequest(
			`/extractionOrder/queryPage/${pageNo}/${pageSize}`,
			params
		),
	transferOrderQuery: (pageNo: Number, pageSize: Number, params: Object) =>
		Api.postRequest(
			`/transferOrder/queryPage/${pageNo}/${pageSize}`,
			params
		),
	extractionOrder: {
		export: (params: Object) =>
			Api.postRequest(`/extractionOrder/export`, params),
		audit: (data: Object) => Api.postRequest(`/extractionOrder/audit`, data),
		batchAudit: (data: Object) =>
			Api.postRequest(`/extractionOrder/batchAudit`, data),
		listAcqPaymentChannel: (params: Object) =>
			Api.postRequest(`/extractionOrder/listAcqPaymentChannel`, params)
	},
	transferOrder: {
		export: (params: Object) =>
			Api.postRequest(`/transferOrder/export`, params)
	}
};
