import axios from "axios";
import store from "@/store";
import router from "@/router";
import { GET_LOGIN_TOKEN, GET_LANGUAGE, GET_COUNTRY } from "@/store/constants";
import { Message } from "element-ui";
import { UAParser } from "ua-parser-js";
import Md5 from "js-md5";
import * as Validate from "@/utils/validate";
import { translate } from "@/utils/tools";

// 创建axios实例
let apiAxios = axios.create({
	baseURL: process.env.VUE_APP_BASE_API, //设置axios基地址
	timeout: 500000
});
// apiAxios.defaults.headers.post['Content-Type'] = 'application/json';

// 响应拦截器
apiAxios.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		if (!error.response) {
			if (error.code == "ECONNABORTED") {
				Message.error(translate("COMMON.SERVER_TIMEOUT"));
			} else {
				Message.error(translate("COMMON.SERVER_EXCEPTION"));
			}
		} else {
			Message.error(error.response.data.message);
			if (
				error.response.status === 401 ||
				error.response.data.code === "10000" ||
				error.response.data.code === "10001"
			) {
				store.dispatch("tagsView/delAllViews", [], { root: true });
				router.push("/login");
				return;
			}
		}
		return Promise.reject(error);
	}
);
// 请求
function request(
	url: string,
	data = {},
	method = "GET",
	isParam = true,
	isFileData = false
) {
	let sign = "";
	if (!isParam && !isFileData) {
		let timestamp = +new Date();
		let dataJson = JSON.stringify(data);
		sign = dataJson + timestamp;
		for (let i = 0; i < 25; i++) {
			sign = Md5.hex(sign);
		}
		data = {
			timestamp: timestamp,
			data: dataJson
		};
	}
	let params: any = isParam
		? { url: url, params: data, method: method }
		: { url: url, data: data, method: method };
	// 请求头拼接token
	apiAxios.defaults.headers = {
		LOGIN_TOKEN: store.getters[GET_LOGIN_TOKEN],
		"app-info": appInfo(sign)
	};
	if (isFileData) {
		let form = new FormData();
		for (let item in data) {
			form.append(item, data[item]);
		}
		params = {
			headers: { ContentType: "multipart/form-data" },
			url: url,
			params: form,
			method: method
		};
	}
	return apiAxios
		.request(params)
		.then(({ data }) => data)
		.catch(data => {
			return (
				(data && data.response && data.response.data) || {
					message: translate("COMMON.SERVER_EXCEPTION"),
					code: 400,
					success: false
				}
			);
		});
}
function appInfo(sign: any) {
	let timestamp = +new Date();
	const uaParser = new UAParser();
	return encodeURIComponent(
		JSON.stringify({
			timestamp: timestamp,
			appName: "agentWeb",
			appVersion: "1.0.0",
			sign: sign,
			loginToken: store.getters[GET_LOGIN_TOKEN],
			browser: uaParser.getBrowser().name,
			platform: "AGENT_WEB",
			language: store.getters[GET_LANGUAGE],
			country: store.getters[GET_COUNTRY]
		})
	);
}

function generalAPI(module: string) {
	return {
		save: (data: {}) => Api.putRequest(`/${module}/save`, data),
		remove: (id: any) => Api.deleteRequest(`/${module}/remove/${id}`),
		edit: (id: any, data: {}) =>
			Api.postRequest(`/${module}/edit/${id}`, data),
		export: (data: {} = {}) => Api.postRequest(`/${module}/export`, data),
		detail: (id: any) => Api.getRequest(`/${module}/detail/${id}`),
		list: (pageNo: number, pageSize: number, data: {} = {}) =>
			Api.postRequest(`/${module}/list/${pageNo}/${pageSize}`, data)
	};
}
let Api = {
	getRequest: (url: string, data = {}) => request(url, data, "GET", false),
	postRequest: (url: string, data = {}) => request(url, data, "POST", false),
	putRequest: (url: string, data = {}) => request(url, data, "PUT", false),
	deleteRequest: (url: string, data = {}) =>
		request(url, data, "DELETE", false),
	getRequestByParam: (url: string, data = {}) =>
		request(url, data, "GET", true),
	postRequestByParam: (url: string, data = {}) =>
		request(url, data, "POST", true),
	putRequestByParam: (url: string, data = {}) =>
		request(url, data, "PUT", true),
	deleteRequestByParam: (url: string, data = {}) =>
		request(url, data, "DELETE", true),
	putfileData: (url: string, data = {}) =>
		request(url, data, "put", false, true),
	generalAPI
};

export default Api;
