import { routerPath, routerPathA } from "../tool";
import Layout from "@/views/layout/Layout.vue";

export default {
	path: "/agentManage",
	name: "agentManage",
	component: Layout,
	meta: {
		title: "代理商管理",
		hasIndex: true,
		tabView: true
	},
	children: [
		routerPathA("agentManage", "agentQuery", "代理商查询", false),
		routerPathA("agentManage", "organizationQuery", "机构查询", false),
		routerPathA("agentManage", "organizationInsert", "新增机构"),
		routerPathA("agentManage", "agentInsert", "新增代理商"),
		routerPathA("agentManage", "agentDetail", "详情"),
		routerPathA("agentManage", "organizationDetail", "详情"),
		routerPathA("agentManage", "agentUpdate", "补充资料"),
		routerPathA("agentManage", "organizationUpdate", "补充资料")
	]
};
