import Api from "@/api/apiAxios";
export default {
	blackWhiteConfig:{
		...Api.generalAPI('blackWhiteConfig')
	},
	agentAdjustCost:{
		...Api.generalAPI('agentAdjustCost')
	},
	merchantAdjustRate:{
		...Api.generalAPI('merchantAdjustRate'),
		importSave:(params:object)=> Api.putRequest(`/merchantAdjustRate/importSave`,params),     		//批量新增
		importDelete:(params:object)=> Api.putRequest(`/merchantAdjustRate/importDelete`,params),     		//批量删除
		export:(params:object)=>Api.postRequest(`/merchantAdjustRate/export`,params), 					//导出
	},
	hideDataAgentConfig:{
		...Api.generalAPI('hideDataAgentConfig'),
		batchRemoveByFile:(params:{}) => Api.deleteRequest(`/hideDataAgentConfig/batchRemoveByFile`,params),
		batchSave:(params:{}) => Api.putRequest(`/hideDataAgentConfig/batchSave`,params), 
	},
	agentAllowanceCost:{
		...Api.generalAPI('agentAllowanceCost'),
	}
};