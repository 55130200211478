import Api from "@/api/apiAxios";
export default {
	dict: {
		...Api.generalAPI('dict'),
		getAllDict: () => Api.getRequest(`/dict/getAllDict`),
	},
	config: {
		...Api.generalAPI('config')
	},
	acqChannel: {
		...Api.generalAPI('acqChannel'),
		pageList: (pageNo: number, pageSize: number, data: {} = {}) => Api.postRequest(`/acqChannel/pageList/${pageNo}/${pageSize}`, data),
		changeAvailable: (channelNo: String, available: String) => Api.putRequest(`/acqChannel/changeAvailable/${channelNo}/${available}`),
		queryByChannelNo: (channelNo: String) => Api.getRequest(`/acqChannel/queryByChannelNo/${channelNo}`),
		queryedit: (data: {}) => Api.putRequest(`/acqChannel/edit`,data),
	},
	listProvinceCity: () => Api.getRequest(`/city/listProvinceCity`),						//获取省市
	listProvinceCityArea:()=> Api.getRequest(`/city/listProvinceCityArea`),					//获取省市区
};
