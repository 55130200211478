export default {
    "COMMON": {
        "CREATE_BY": "Create By",
        "CREATE_TIME": "Create Time",
        "UPDATE_BY": "Update By",
        "SORT": "Sort",
        "UPDATE_TIME": "Update Time",
        "LAST_UPDATE_TIME": "Last Update Time",
        "REMARK": "Remark",
        "START_DATE": "Start Date",
        "END_DATE": "End Date",
        "OPERATION": "Operation",
        "SERVER_TIMEOUT": "Server timeout, please try again later",
        "SERVER_EXCEPTION": "Server exception, please try again later",
        "HOME": "Home",
        "REFRESH": "Refresh",
        "CLOSE": "Close",
        "CLOSE_OTHER": "Close other",
        "CLOSE_ALL": "Close all",
        "IMAGE_CONTROL_TYPES": "The image type of the ossupload control can only be of the following types",
        "USER_PROFILE": "User Profile",
        "BAI_DU": "BaiDu",
        "KEYWORD": "keyword",
        "ALL": "All",
        "OPERATION_SUCCESS": "Operation success",
        "STATUS": "Status",
        "IMAGE_CONTROL_LIMIT": "You can only upload {param} files at most!",
        "IMAGE_CONTROL_SUFFIX": "The suffix of the uploaded file can only be in {param} format!",
        "UPLOAD_FILE_CONTROL_SIZE": "Upload file size cannot exceed {param} MB!"
    },
    "MODULE": {
        "CONFIG": "Config",
        "DICT": "Dict",
        "AUTHORITY": "Authority",
        "LOGIN_INFO": "Login Info",
        "OPER_LOG": "Oper Log",
        "ROLE": "Role",
        "MENU": "Menu",
        "USER": "User",
        "THIRD_USER_LOGIN": "Third User Login"
    },
    "BPMN": {
        "SHORTCUT_KEYS": "Shortcut keys",
        "BPMN_DIAGRAM": "BPMN diagram",
        "SVG_IMAGE": "SVG image",
        "UNDO": "Undo",
        "REDO": "Redo",
        "RESET_ZOOM": "Reset zoom",
        "ZOOM_OUT": "Zoom out",
        "ZOOM_IN": "Zoom in"
    },
    "FROM": {
        "PLACEHOLDER": "Please enter {param}",
        "SUBMIT": "Submit",
        "CANCEL": "Cancel",
        "ADD_MODULE": "Add {module}",
        "UPDATE_MODULE": "Update {module}",
        "ADD_SUCCESS": "Add success",
        "UPDATE_SUCCESS": "Update success",
        "DELETE_SUCCESS": "Delete Success",
        "WARNING": "Warning",
        "CONFIRM_EXPORT": "Are you sure to export data of {module}?",
        "CONFIRM_DELETE": "Are you sure to delete the No.{id} of {module}?",
        "CONFIRM_OPERATION": "Are you sure to {operation} {module}?",
        "DISABLE": "DISABLE",
        "ENABLE": "ENABLE"
    },
    "OPERATION": {
        "QUERY": "Query",
        "RESET": "Reset",
        "ADD": "Add",
        "EXPORT": "Export",
        "UPDATE": "Update",
        "DELETE": "Delete"
    },
    "LOGIN": {
        "LOGIN_TITLE": "Boss Login",
        "LOGOUT": "Logout",
        "LOGIN_NAME": "Login Name",
        "PASSWORD": "Password",
        "IMAGE_VERIFICATION_CODE": "verification code",
        "LOGIN_BUTTON": "Login",
        "LOGIN_NAME_OR_PASSWORD": "Login Name or Password",
        "FORCE_LOGOUT_SUCCESS": "Force logout success"
    },
    "CONFIG": {
        "ID": "Id",
        "CODE": "Code",
        "VALUE": "Value",
        "DESCRIPTION": "Description",
        "STATUS": "Status"
    },
    "DICT": {
        "ID": "Id",
        "PARENT_ID": "Parent Id",
        "ANCESTORS": "Ancestors",
        "CODE": "Code",
        "NAME": "Name",
        "VALUE": "Value",
        "SORT": "Sort"
    },
    "SEQUENCE": {
        "ID": "Id",
        "NAME": "Name",
        "PREFIX": "Prefix",
        "DESCRIPTION": "Description",
        "CURRENT_NUM": "Current Num",
        "NUM_LENGTH": "Num Length",
        "INCREMENT": "Increment"
    },
    "AUTHORITY": {
        "AUTH_ID": "Auth Id",
        "GROUP_CODE": "Group Code",
        "MODULE": "Module",
        "CODE": "Code",
        "NAME": "Name",
        "MENU_ID": "Menu Id",
        "TYPE": "Type"
    },
    "DEPARTMENT": {
        "DEPT_ID": "Dept Id",
        "ID": "Id",
        "PARENT_ID": "Parent Id",
        "ANCESTORS": "Ancestors",
        "DEPT_NAME": "Dept Name",
        "SORT": "Sort",
        "LEADER_ID": "Leader Id",
        "PHONE": "Phone",
        "EMAIL": "Email",
        "STATUS": "Status",
        "DEL_FLAG": "Del Flag"
    },
    "LOGIN_INFO": {
        "ID": "Id",
        "SESSION_ID": "Session Id",
        "USER_ID": "User Id",
        "IDENTITY_ID": "Identity Id",
        "LOGIN_TYPE": "Login Type",
        "LOGIN_TIME": "Login Time",
        "PLATFORM": "Platform",
        "IP_ADDR": "Ip Addr",
        "LOCATION_ID": "Location Id",
        "LOCATION": "Location",
        "BROWSER": "Browser",
        "OS": "Os",
        "STATUS": "Status"
    },
    "MENU": {
        "MENU_ID": "Menu Id",
        "ID": "Id",
        "NAME": "Name",
        "PARENT_ID": "Parent Id",
        "SORT": "Sort",
        "URL": "Url",
        "TARGET": "Target",
        "STATUS": "Status",
        "ICON": "Icon"
    },
    "OPER_LOG": {
        "ID": "Id",
        "TITLE": "Title",
        "BUSINESS_TYPE": "Business Type",
        "METHOD": "Method",
        "USER_ID": "User Id",
        "USER_NAME": "User Name",
        "URL": "Url",
        "IP": "Ip",
        "LOCATION_ID": "Location Id",
        "LOCATION": "Location",
        "SYSTEM": "System",
        "BROWSER": "Browser",
        "APP_INFO": "App Info",
        "PARAMS": "Params",
        "RESULT": "Result",
        "STATUS": "Status",
        "MESSAGE": "Message",
        "COST_TIME": "Cost Time"
    },
    "POST": {
        "POST_ID": "Post Id",
        "ID": "Id",
        "PARENT_ID": "Parent Id",
        "ANCESTORS": "Ancestors",
        "DEPT_ID": "Dept Id",
        "POST_NAME": "Post Name",
        "SORT": "Sort",
        "STATUS": "Status"
    },
    "POST_ROLE": {
        "ID": "Id",
        "POST_ID": "Post Id",
        "ROLE_ID": "Role Id"
    },
    "ROLE": {
        "ROLE_ID": "Role Id",
        "ID": "Id",
        "NAME": "Name",
        "ROLE_CODE": "Role Code",
        "SORT": "Sort",
        "STATUS": "Status",
        "DEL_FLAG": "Del Flag"
    },
    "ROLE_AUTHORITY": {
        "ID": "Id",
        "ROLE_ID": "Role Id",
        "AUTH_CODE": "Auth Code"
    },
    "ROLE_MENU": {
        "ID": "Id",
        "ROLE_ID": "Role Id",
        "MENU_ID": "Menu Id",
        "FOR_ROLE": "For role",
        "ASSIGNED_MENU": "assigned menu and authority",
        "MENU_TREE": "Menu tree",
        "SHOW_MENU": "Show menu",
        "MENU_AUTHORITY": "Authority of Menu"
    },
    "THIRD_USER_AUTH": {
        "ID": "Id",
        "USER_ID": "User Id",
        "IDENTITY_TYPE": "Identity Type",
        "CREDENTIAL": "Credential",
        "NICKNAME": "Nickname",
        "AVATAR": "Avatar",
        "SEX": "Sex",
        "LAST_LOGIN_TIME": "Last Login Time",
        "LAST_LOGIN_IP": "Last Login Ip",
        "ACCESS_TOKEN": "Access Token",
        "REFRESH_TOKEN": "Refresh Token"
    },
    "USER": {
        "USER_ID": "User Id",
        "ID": "Id",
        "NICKNAME": "Nickname",
        "LOGIN_NAME": "Login Name",
        "PHONE": "Phone",
        "EMAIL": "Email",
        "PASSWORD": "Password",
        "SALT": "Salt",
        "SEX": "Sex",
        "AVATAR": "Avatar",
        "STATUS": "Status",
        "USER_TYPE": "User Type",
        "NEED_MODIFY_PASSWORD": "Need Modify Password",
        "DEL_FLAG": "Del Flag",
        "FORCE_LOGOUT_TIP": "Are you sure to force logout No.{param} of user?",
        "ALL_FORCE_LOGOUT_TIP": "Are you sure to force logout all No.{param} of user?"
    },
    "USER_POST": {
        "ID": "Id",
        "USER_ID": "User Id",
        "POST_ID": "Post Id"
    },
    "USER_ROLE": {
        "ID": "Id",
        "USER_ID": "User Id",
        "ROLE_ID": "Role Id",
        "FOR_USER": "For user",
        "ASSIGN_ROLE": "Assign role",
        "UNASSIGNED_ROLE": "Unassigned role"
    },
    "MENU_TREE": {
        "USER_AUTHORITY": "User Authority",
        "USER_MANAGE": "User Manage",
        "ROLE_MANAGE": "Role Manage",
        "AUTHORITY_MANAGE": "Authority Manage",
        "MENU_MANAGE": "Menu Manage",
        "OPER_LOG_MANAGE": "Oper Log Manage",
        "LOGIN_INFO_MANAGE": "Login Info Manage",
        "BASIC_CONFIG": "Basic Config",
        "DICT_MANAGE": "Dict Manage",
        "CONFIG_MANAGE": "Config Manage",
        "ENTRANCE_CONFIG": "Entrance Config",
        "DEV_MANAGE": "Dev Manage",
        "DEV_DOC_MANAGE": "Dev Doc Manage",
        "SWAGGER_DOC_MANAGE": "Swagger Doc Manage",
        "HOME": "Home",
        "USER_ASSIGNED_ROLE": "Assigned Role",
        "USER_ASSIGNED_AUTHORITY": "Assigned Authority"
    }
}