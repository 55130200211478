import { routerPath, routerPathA } from "../tool";
import Layout from "@/views/layout/Layout.vue";

export default {
	path: "/newsCore",
	name: "newsCore",
	component: Layout,
	meta: {
		title: "消息中心",
		hasIndex: true,
		tabView: true
	},
	children: [
        routerPathA('newsCore', 'newsRecord', '消息记录'),
	]
};
