import Api from "@/api/apiAxios";
export default {
	policyInfo:{
		...Api.generalAPI('policyInfo'),
		getPolicyList: () => Api.getRequest(`/policyInfo/policyList`),
		getAgentPolicyList: (agentNo: Number) => Api.getRequest(`/policyInfo/agentPolicyList/${agentNo}`),
	},
	activityInfo:{
		...Api.generalAPI('activityInfo'),
		queryPage:(pageNo:Number,pageSize:Number,params:object)=>Api.postRequest(`/activityInfo/list/${pageNo}/${pageSize}`,params), 
	},
	terminalActivity:{
		queryPage:(pageNo:Number,pageSize:Number,params:object)=> Api.postRequest(`/terminalActivity/queryPage/${pageNo}/${pageSize}`,params),     //机具活动
		queryTerminalActivityDetail:(id:Number)=> Api.postRequest(`/terminalActivity/queryTerminalActivityDetail/${id}`),     		//返现明细
		export:(params:object)=> Api.postRequest(`/terminalActivity/export`,params),     		//导出
	},
	merchantActivity:{
		queryPage:(pageNo:Number,pageSize:Number,params:object)=> Api.postRequest(`/merchantActivity/queryPage/${pageNo}/${pageSize}`,params),     //商户活动
		querymerchantActivityDetail:(id:Number)=> Api.postRequest(`/merchantActivity/queryMerchantActivityDetail/${id}`),     					//返现明细
		export:(params:object)=> Api.postRequest(`/merchantActivity/export`,params),     		//导出
	},
};
