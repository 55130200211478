
<template>
	<el-upload ref="elUpload" :class="className" :action="uploadUrl" :data='ossParam' :limit="limit" :list-type="listType"
		:accept="accept" :show-file-list="!isOneImg" :on-success="handleAvatarSuccess" :on-remove="handleRemove"
		:on-change="handleChange" :before-upload="beforeAvatarUpload" :on-exceed="handleExceed">
		<template v-if="!isOneImg">
			<i class="el-icon-plus"></i>
		</template>
		<template v-else>
			<img v-if="imageUrl" :src="imageUrl" :key="imageUrl" class="avatar">
			<i v-else class="el-icon-plus"></i>
		</template>
		<!-- <i class="el-icon-plus"></i> -->
	</el-upload>
</template>
<script>
import { CommonApi } from '@/api';
import { guid } from '@/utils/tools';

const imageTypeList = [
	{ value: 'agent', label: '代理商' },
	{ value: 'other', label: '其他' },
];
export default {
  changename: 'OssUpload',
	props: {
		listType: {
			type: String,
			default: "picture-card",
			validator: function (value) {
				return ['picture-card', 'picture', 'text'].indexOf(value) !== -1
			}
		},
		imageType: {
			type: String,
			default: "other",
			validator: function (value) {
				return imageTypeList.map(item => item.value).indexOf(value) !== -1
			}
		},
		accept: {
			type: String,
		},
		limit: {
			type: Number,
			default: 1
		},
		suffix: {
			type: String,
			default: "*"
		},
		limitSizeMb: {
			type: Number,
			default: 3
		},
		className: {
			type: String,
			default: "avatar-uploader"
		},
		isOneImg: {
			type: Boolean,
			default: false
		},
		oldImg: {
			type: String,
			default: ''
		},
		isUpdate: {
			type: Boolean,
			default: false
		},
		ocrType: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			imageUrl: '',
			ossParam: {},
			ossSign: {},
			uploadUrl: '',
			fileList: [],
			el: null,
			isimgChange: false
		};
	},
	watch: {
		oldImg: {
			handler(val) {
				if (val && this.isUpdate && !this.isimgChange) {
					this.imageUrl = val
				}
			},
			immediate: true
		}
	},
	created() {

	},
	mounted() {
		this.el = this.$refs.elUpload;
	},
	methods: {
		clearFiles() {
			this.el.clearFiles();
		},
		handleAvatarSuccess(res, file, fileList) {
			this.fileList = fileList;
			this.imageUrl = URL.createObjectURL(file.raw);
			this.isimgChange = true
			if (this.ocrType) {
				let params = {
					"ocrType": this.ocrType,
					"path": res.data.filename
				}
				CommonApi.ocr(params)
					.then(result => {
						if (this.ocrType == 'BANK_CARD') {
						this.$emit("change", fileList, res.data.filename, result.data.result || {});
						} else if (this.ocrType == 'ID_CARD_FRONT') {
						if (!result.data.words_result['公民身份号码']) {
							this.imageUrl = ''
							this.Message('请上传清晰的身份证正面照片')
							this.$emit("change", '', '', {});
							return
						} else {
							this.$emit("change", fileList, res.data.filename, result.data.words_result || {});
						}
						} else if (this.ocrType == 'BUSINESS_LICENSE') {
						if (!result.data.words_result['社会信用代码'].words) {
							this.imageUrl = ''
							this.Message('请上传清晰的营业执照照片')
							this.$emit("change", '', '', {});
							return
						} else {
							this.$emit("change", fileList, res.data.filename, result.data.words_result || {});
						}
						} else {
						this.$emit("change", fileList, res.data.filename, result.data.words_result || {});
						}
					})
					.catch(err=>{
						this.$emit("change", fileList, res.data.filename, {});
					})
			} else {
				this.$emit("change", fileList, res.data.filename, {});
			}
		},
		handleRemove(file, fileList) {
			let fileNames = fileList
				.map(item => item && item.response && item.response.data && item.response.data.filename || '')
				.filter(item => !!item);
		},
		handleChange(file, fileList) {
			if (this.isOneImg) {
				this.limit++
			}
			let fileNames = fileList
				.map(item => item && item.response && item.response.data && item.response.data.filename || '')
				.filter(item => !!item);
		},
		handleExceed() {
			this.$message.error(this.translate('COMMON.IMAGE_CONTROL_LIMIT', { param: this.limit.toString() }));
		},
		async beforeAvatarUpload(file) {
			const isLt2M = file.size / 1024 / 1024 < this.limitSizeMb;
			const fileSuffix = file.name.split(".").pop().toLowerCase();
			if (this.suffix !== "*") {
				let indexOf = this.suffix.toLowerCase().split(";").indexOf(fileSuffix);
				if (indexOf < 0) {
					this.$message.error(this.translate('COMMON.IMAGE_CONTROL_SUFFIX', { param: this.suffix }));

					return false;
				}
			}
			if (!isLt2M) {
				this.$message.error(`上传图片最大不能超过${this.limitSizeMb}M`);
				return false;
			}
			if (imageTypeList.map(item => item.value).indexOf(this.imageType) < 0) {
				const imageTypeHtml = imageTypeList.map(item => `<li>${item.label} -- ${item.value}</li>`).join("");
				this.$message.error({
					dangerouslyUseHTMLString: true,
					message: `<strong>${translate('COMMON.IMAGE_CONTROL_TYPES')} <ul>${imageTypeHtml}<ul></strong>`
				});
				return false;
			}
			this.ossSign = (await CommonApi.aliyunOssSign()).data || {};
			this.uploadUrl = this.ossSign.host;
			let now = new Date();
			let nowDate = `${now.getFullYear()}${now.getMonth() + 1}${now.getDate()}/`;
			let name = `${guid()}.${file.name.split(".").pop()}`;
			let key = `${this.ossSign.dir}${this.imageType}/${nowDate}${name}`;
			this.ossParam = {
				name: key,
				key: key,
				policy: this.ossSign.policy,
				OSSAccessKeyId: this.ossSign.accessId,
				callback: this.ossSign.callback,
				signature: this.ossSign.signature,
			};
			return true;
		}
	}
}
</script>
<style>
.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.avatar-uploader .el-upload:hover {
	border-color: #409EFF;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}

.avatar {
	width: 100%;
	height: 100%;
	display: block;
}
</style>
