/**
 * @author kco1989
 * @email kco1989@qq.com
 * @date 2019-11-30 13:59
 */
import {routerPath, routerPathA} from '../tool';
import Layout from '@/views/layout/Layout.vue'

export default {
    path: '/basicConfig',
    name: 'basicConfig',
    component: Layout,
    meta: {
        title: 'MENU_TREE.BASIC_CONFIG',
        hasIndex: true,
		tabView: true
    },
    children: [
        routerPath('basicConfig', 'index', 'MENU_TREE.BASIC_CONFIG'),
        routerPathA('basicConfig', 'dictManage', 'MENU_TREE.DICT_MANAGE'),
        routerPathA('basicConfig', 'configManage', 'MENU_TREE.CONFIG_MANAGE'),
        routerPathA('basicConfig', 'entranceConfig', 'MENU_TREE.ENTRANCE_CONFIG')
    ]
};
