import { routerPath, routerPathA } from "../tool";
import Layout from "@/views/layout/Layout.vue";

export default {
	path: "/financialTools",
	name: "financialTools",
	component: Layout,
	meta: {
		title: "财务工具",
		hasIndex: true,
		tabView: true
	},
	children: [
		routerPathA("financialTools", "stopPaymentManage", "止付管理", false),
		routerPathA(
			"financialTools",
			"agentAccountQuery",
			"代理商账户查询",
			false
		),
		routerPathA("financialTools", "withHoldManage", "代扣管理", false),
		routerPathA("financialTools", "execlDownload", "分润明细下载", false),
		routerPathA("financialTools", "accountAdjustManage", "调账管理", false)
	]
};
