import Api from "@/api/apiAxios";
export default {
	role: {
		...Api.generalAPI("role"),
		listRoleMenuAuth: (roleId: string, menuId: string) =>
			Api.getRequest(`/role/listRoleMenuAuth/${roleId}/${menuId}`),
		updateRoleMenuAuth: (
			select: boolean,
			roleId: string,
			menuId: string,
			data: []
		) =>
			Api.postRequest(
				`/role/updateRoleMenuAuth/${
					select ? "select" : "unSelect"
				}/${roleId}/${menuId}`,
				data
			)
	},
	user: {
		...Api.generalAPI("user"),
		listUserRole: (userId: number) =>
			Api.getRequest(`/user/listUserRole/${userId}`),
		updateUserRole: (userId: number, data: []) =>
			Api.postRequest(`/user/updateUserRole/${userId}`, data),
		updatePassword: (params: {}) =>
			Api.postRequest(`/user/updatePassword`, params)
	},
	authority: {
		...Api.generalAPI("authority")
	},
	menu: {
		...Api.generalAPI("menu"),
		listMenuTree: () => Api.getRequest(`/menu/listMenuTree`)
	},
	operLog: {
		...Api.generalAPI("operLog")
	},
	loginInfo: {
		...Api.generalAPI("loginInfo"),
		forceLogout: (id: number) =>
			Api.deleteRequest(`/loginInfo/forceLogout/${id}`),
		forceLogoutAll: (id: number) =>
			Api.deleteRequest(`/loginInfo/forceLogoutAll/${id}`)
	},
	noticeInfo: {
		...Api.generalAPI("noticeInfo")
	},
	// 导出商户管理
	merchantInfo: {
		...Api.generalAPI("merchantInfo")
	}
};
