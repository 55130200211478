/**
 * @author kco1989
 * @email kco1989@qq.com
 * @date 2019-12-16 16:11
 */
import store from '@/store'
import {GET_AUTH_CODES,GET_LOGIN_USER} from "@/store/constants";
import {VNode} from "vue";
import {DirectiveBinding} from "vue/types/options";

export default {
	inserted(el: HTMLElement, binding: DirectiveBinding, vnode: VNode, oldVnode: VNode) {
		let {value} = binding;
		const loginUser = store.getters[GET_LOGIN_USER] || {};
		if (loginUser && loginUser.userInfo && loginUser.userInfo.userType === 'ADMIN') {
			return true;
		}
		const permissions = store.getters[GET_AUTH_CODES] || [];
		if (value && typeof value === 'string') {
			value = [value];
		}
		if (value && value instanceof Array && value.length > 0) {
			const permissionFlag = value;

			const hasPermissions = permissions.some((permission: string) => {
				return permissionFlag.includes(permission)
			});

			if (!hasPermissions) {
				el.parentNode && el.parentNode.removeChild(el)
			}
		} else {
			throw new Error(`请设置操作权限标签值`);
		}
	}
};
