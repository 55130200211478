import {routerPath, routerPathA} from '../tool';
import Layout from '@/views/layout/Layout.vue'

export default {
    path: '/specialListConfig',
    name: 'specialListConfig',
    component: Layout,
    meta: {
        title: '特殊名单配置',
        hasIndex: true,
		tabView: true
    },
    children: [
        routerPathA('specialListConfig', 'BWListManage', '黑白名单管理',false),
        routerPathA('specialListConfig', 'agentAdjustCost', '调价管理',false),
        routerPathA('specialListConfig', 'merchantAdjustRate', '商户调价管理',false),
        routerPathA('specialListConfig', 'hideDataAgentConfig', '隐藏交易名单',false),
        routerPathA('specialListConfig', 'agentAllowanceCost', '代理商津贴',false),
    ]
};
