import {routerPath, routerPathA} from '../tool';
import Layout from '@/views/layout/Layout.vue'

export default {
    path: '/withdrawManage',
    name: 'withdrawManage',
    component: Layout,
    meta: {
        title: '提现管理',
        hasIndex: true,
		tabView: true
    },
    children: [
        routerPathA('withdrawManage', 'withdrawOrderQuery', '提现订单查询',false),
        routerPathA('withdrawManage', 'transferOrderQuery', '代付流水查询',false),
    ]
};
