<template>
	<div class="app-wrapper" :class="classObj">
<!--		<div v-if="isMobile && sidebar.opened" @click="handleClickOutside"></div>-->
		<SideBar class="sidebar-container"></SideBar>
		<div class="main-container">
			<HeaderNavbar></HeaderNavbar>
			<TagsView v-show="hasTagsView"></TagsView>
			<AppMain id="app-main"></AppMain>
		</div>
		<RightPanel>
			<Settings></Settings>
		</RightPanel>
	</div>
</template>

<script>
	// @ is an alias to /src
	import {mapActions,mapState} from 'vuex';
	import store from '@/store'
	import SideBar from "./components/sideBar/SideBar";
	import HeaderNavbar from "./components/HeaderNavbar";
	import TagsView from "./components/tagsView/TagsView";
	import AppMain from "./components/AppMain";
	import RightPanel from "./components/rightPanel/RightPanel";
	import Settings from "./components/rightPanel/Settings";
	import Driver from 'driver.js' // import driver.js
	import 'driver.js/dist/driver.min.css' // import driver.js css
	import steps from './steps'

	const {body} = document;
	const WIDTH = 992;

	export default {
		name: 'Layout',
		components: {
			SideBar,
			HeaderNavbar,
			TagsView,
			AppMain,
			RightPanel,
			Settings
		},
		data() {
			return {
				driver: null
			}
		},
		beforeMount: function(){
			this.getUserAgent();
			window.addEventListener('resize', this.$_resizeHandler)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.$_resizeHandler)
		},
		mounted() {
			const isMobile = this.$_isMobile();
			// if (isMobile) {
			// 	this.setDeviceMode(true);
			// 	this.closeSideBar(true)
			// }
			// this.driver = new Driver();
			this.guide();
		},
		computed: {
			...mapState('app', ['sidebar', 'breadcrumbs', 'isMobile', 'hasTagsView', 'showGuide', 'isLogin']),
			classObj() {
				return {
					// hideSidebar: !this.sidebar.opened,
					openSidebar: this.sidebar.opened,
					withoutAnimation: this.sidebar.withoutAnimation,
					// mobile: this.isMobile
				}
			}
		},
		methods: {
			...mapActions('userAgent', ['getUserAgent']),
			...mapActions('app', ['setDeviceMode', 'toggleSideBar', 'closeSideBar', 'doNotShowGuide']),
			guide() {
				if(this.isLogin && this.showGuide){
					// this.driver.defineSteps(steps);
					// this.driver.start();
					this.doNotShowGuide();
				}
			},
			handleClickOutside() {
				this.closeSideBar(false);
			},
			$_isMobile() {
				const rect = body.getBoundingClientRect();
				return rect.width - 1 < WIDTH
			},
			$_resizeHandler() {
				if (!document.hidden) {
					let isMobile = this.$_isMobile();
					this.setDeviceMode(this.$_isMobile());
					// if (isMobile) {
					// 	this.closeSideBar(true);
					// }
				}
			}
		},
		watch: {
			$route(route) {
				if (this.isMobile && this.sidebar.opened) {
					// this.closeSideBar(false);
				}
			}
		},
	}
</script>

<style lang="scss" scoped>
	@import "../../style/mixin.scss";
	@import "../../style/variables.scss";

	.app-wrapper {
		@include clearfix;
		position: relative;
		height: 100%;
		width: 100%;

		&.mobile.openSidebar {
			position: fixed;
			top: 0;
		}
	}

	.drawer-bg {
		background: #000;
		opacity: 0.3;
		width: 100%;
		top: 0;
		height: 100%;
		position: absolute;
		z-index: 999;
	}

	.fixed-header {
		position: fixed;
		top: 0;
		right: 0;
		z-index: 9;
		width: calc(100% - #{$sideBarWidth});
		transition: width 0.28s;
	}

	.hideSidebar .fixed-header {
		width: calc(100% - 54px)
	}

	.mobile .fixed-header {
		width: 100%;
	}
</style>
