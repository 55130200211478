import Api from "@/api/apiAxios";
export default {
	suspendOrder: {
		...Api.generalAPI("suspendOrder"),
		queryAgentInfo: (agentNo: Number) =>
			Api.putRequest(`/suspendOrder/queryAgentInfo/${agentNo}`),
		queryPage: (pageNo: Number, pageSize: Number, params: Object) =>
			Api.postRequest(
				`/suspendOrder/queryPage/${pageNo}/${pageSize}`,
				params
			),
		delete: (id: Number, params: {}) =>
			Api.putRequest(`/suspendOrder/remove/${id}`, params)
	},
	// 代理商
	agentAccount: {
		...Api.generalAPI("agentAccount"),
		// 账户汇总
		sumList: (params: Object) =>
			Api.postRequest("agentAccount/sumList", params),
		// 新增调账记录
		insertAdjustment: (data: {}) =>
			Api.postRequest(`/agentAccount/adjust/save`, data),
		// 获取调账列表
		getAgentAccountList: (pageNo, pageSize, data) =>
			Api.postRequest(
				`agentAccount/adjust/list/${pageNo}/${pageSize}`,
				data
			),
		// 导出代理商调账记录
		exportAgentAccountList: data =>
			Api.postRequest(`/agentAccount/adjust/export`, data),
		// 导出代理商查询列表
		exportAgentInfoAgent: data =>
			Api.postRequest(`/agentInfo/agent/export`, data),
		// 导出机构查询列表
		exportAgentinfoOrg: data =>
			Api.postRequest(`/agentInfo/org/export`, data)
	},
	withholdingOrder: { ...Api.generalAPI("withholdingOrder") },
	// 导出
	execlDownloadInfo: {
		...Api.generalAPI("execlDownloadInfo"),
		put: (params: {}) => Api.putRequest(`/execlDownloadInfo/save`, params),
		// 保存分润明细excel异步导出
		postExeclDownloadInfo: data =>
			Api.postRequest(`/execlDownloadInfo/save`, data),
		reDownload: (params: {}) =>
			Api.postRequest(`/execlDownloadInfo/reDownload`, params)
	},
	// 代理商查询配置详情
	configDetail: {
		// 代理商查询配置详情
		getConfigDetail: (agentNo, policyNo) => Api.getRequest(`agentAdjustProfitConfig/configDetail/${agentNo}/${policyNo}`),
		// editSaveConfig: (agentNo, policyNo, data) => Api.putRequest(`agentAdjustProfitConfig/saveConfig/${agentNo}/${policyNo}`, data)
		editSaveConfig: (data) => Api.putRequest(`agentAdjustProfitConfig/saveConfig`, data)
	}
};
