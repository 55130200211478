import Api from "@/api/apiAxios";
export default {
	terminalInfo:{
		...Api.generalAPI('terminalInfo'),
		getActivityName: (agentNo:String) => Api.getRequest(`/terminalInfo/activityName/${agentNo}`),
		batchUpdate: (params:Object) => Api.putRequest(`/terminalInfo/batchUpdate`,params),
		putSave: (params:Object) => Api.putfileData(`/terminalInfo/save`,params),
		update: (params:Object) => Api.postRequest(`/terminalInfo/update`,params),
		batchRemove: (params:Object) => Api.deleteRequest(`/terminalInfo/removeByFile`,params),
		unbindSn: (sn:String) => Api.postRequest(`/terminalInfo/unbindSn/${sn}`),
		bindSn: (sn:String,merchantNo:String) => Api.postRequest(`/terminalInfo/bindSn/${sn}/${merchantNo}`),
		save: (params:{}) => Api.postRequest(`/terminalInfo/save`,params),
		queryMerchantName: (merchantNo:String) => Api.postRequest(`/merchantInfo/queryMerchantName/${merchantNo}`),
		queryTerminalOperRecord:(pageNo:Number,pageSize:Number,data:Object) => Api.postRequest(`/terminalInfo/queryTerminalOperRecord/${pageNo}/${pageSize}`,data)
	},
	hardwareInfo:{
		...Api.generalAPI('hardwareInfo'),
		getHardwareList: () => Api.getRequest(`/hardwareInfo/hardwareList`),
		editHardware: (params:{}) => Api.postRequest(`/hardwareInfo/edit`,params),
	},
	reworkMapping:{
		...Api.generalAPI('reworkMapping'),
		queryPage: (pageNo: Number,pageSize: Number,params: Object) => Api.postRequest(`/reworkMapping/queryPage/${pageNo}/${pageSize}`,params),
		delete: (id: Number) => Api.putRequest(`/reworkMapping/delete/${id}`),
	},
	queryInfo:{
		getActivityInfo: () => Api.getRequest(`/activityInfo/activityList`),
	},
	acqChannel: {
		// 查询所有上游通道信息
		listAllChannel: () => Api.getRequest("/acqChannel/listAllChannel")
	}
};
