<template>
	<!-- 页面整体布局容器 -->
	<div class="wrap" >
		<!-- 登录框容器 -->
		<div class="login-box">
			<!-- 登录页面的图片 -->
			<img src="../assets/login-img.png" class="login-img">
			<!-- 登录表单获取容器 -->
			<div class="logGet">
				<!-- 登录标题提示 -->
				<div class="logD logDtip">
					<p class="p1">{{ translate('LOGIN.LOGIN_TITLE') }}</p>
				</div>
				<!-- 手机号输入区域 -->
				<div class="lgD">
					<img src="../assets/phone.png" width=20 height=20>
					<input ref="usernameInput" type="text" v-model='username' maxlength="11"
						   :placeholder="translate('FROM.PLACEHOLDER', { param: 'LOGIN.LOGIN_NAME' })" @keyup.enter="keyUp">
				</div>
				<!-- 密码输入区域 -->
				<div class="lgD">
					<img src="../assets/password.png" width=20 height=20>
					<input ref="passwordInput" type="password" v-model='password'
						   :placeholder="translate('FROM.PLACEHOLDER', { param: 'LOGIN.PASSWORD' })" @keyup.enter="keyUp">
				</div>
				<!-- 图形验证码输入区域 -->
				<div class="lgD">
					<img src="../assets/code.png" width=20 height=20>
					<input ref="imageCodeInput" maxlength="4" type="text" v-model='imageCode' placeholder="请输入图形验证码"
						   @keyup.enter="keyUp">
					<!-- 刷新验证码图片 -->
					<img class="imageCode" :src="imgCodeSrc" width=100 height=25 @click="refreshImageCode">
				</div>
				<!-- 登录按钮区域 -->
				<div class="logD">
					<el-button ref="loginBtn" type="primary" @click="login">登录</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { guid } from "@/utils/tools";
import { CommonApi } from "@/api";
export default {
	name: "Login",
	data: function() {
		return {
			username: "",
			password: "",
			imageCode: "",
			imageGuid: ""
		};
	},
	mounted() {
		this.refreshImageCode();
	},
	computed: {
		...mapState("app", {
			userInfo: "userInfo",
			isLogin: "isLogin"
		}),
		imgCodeSrc: function() {
			return CommonApi.getImageCode(this.imageGuid);
		}
	},
	methods: {
		...mapActions("app", ["userLogin"]),
		refreshImageCode: function() {
			this.imageGuid = guid();
			console.log("imageGuid = " + this.imageGuid);
		},
		login: async function() {
			if (!this.username || !this.password) {
				this.$message.warning(
					this.translate("FROM.PLACEHOLDER", {
						param: "LOGIN.LOGIN_NAME_OR_PASSWORD"
					})
				);
				return;
			}
			if (!this.imageCode) {
				this.$message.warning(
					this.translate("FROM.PLACEHOLDER", {
						param: "LOGIN.IMAGE_VERIFICATION_CODE"
					})
				);
				return;
			}
			await this.userLogin({
				username: this.username,
				password: this.password,
				imageCode: this.imageCode,
				imageGuid: this.imageGuid
			});
			if (this.isLogin) {
				this.$router.push("/home");
			} else {
				this.password = "";
				this.imageCode = "";
				this.refreshImageCode();
			}
		},
		keyUp: function(event) {
			if (this.username === "") {
				this.$message.warning(
					this.translate("FROM.PLACEHOLDER", {
						param: "LOGIN.LOGIN_NAME"
					})
				);
				return;
			}
			if (this.password === "") {
				this.$message.warning(
					this.translate("FROM.PLACEHOLDER", {
						param: "LOGIN.PASSWORD"
					})
				);
				return;
			}
			if (this.imageCode === "") {
				this.$message.warning(
					this.translate("FROM.PLACEHOLDER", {
						param: "LOGIN.IMAGE_VERIFICATION_CODE"
					})
				);
				return;
			}
			this.login();
		}
	}
};
</script>

<style lang="scss" scoped>
* {
	margin: 0;
	padding: 0;
}

.wrap {
	/* 使用 url 加载背景图片，请确保路径正确 */
	background-image: url("../assets/login_bg.jpg");
	/* 优化背景图片的显示 */
	background-size: cover; /* 使用 cover 代替具体的百分比，以适应不同尺寸的屏幕 */
	background-repeat: no-repeat;
	background-position: center; /* 简化写法，兼容性良好 */
	/* 使用现代布局方案，如 Flexbox 或 Grid，这里保持原有设置 */
	height: 100vh;
	width: 100%;
	/* 避免不必要的注释，删除了之前未使用的 background-position 属性 */
	position: relative;
}

.head {
	height: 120px;
	width: 100%;
	background-color: #66cccc;
	text-align: center;
	position: relative;
}

.wrap .logGet {
	height: 408px;
	width: 480px;
	padding: 0 60px;
	position: absolute;
	background-color: #ffffff;
	top: 0px;
	right: 0px;
}

.logC button {
	width: 100%;
	height: 45px;
	background-color: #ee7700;
	border: none;
	color: white;
	font-size: 18px;
}

.logGet .logD.logDtip .p1 {
	font-size: 26px;
	line-height: 26px;
	padding: 60px 0 10px;
	font-weight: 700;
}

.errorMsg {
	color: red;
}

.wrap .logGet .logD.logDtip {
	margin-bottom: 20px;
	margin-top: 0px;
	margin-right: auto;
	margin-left: auto;
}

.logGet .lgD img {
	position: absolute;
	top: 13px;
	left: 8px;
}

.logGet .lgD img.imageCode {
	position: absolute;
	top: 10px;
	left: 250px;
	cursor: pointer;
}

.logGet .lgD input {
	width: 100%;
	height: 48px;
	text-indent: 2.5rem;
	border: none;
}

.wrap .logGet .lgD {
	position: relative;
	margin-bottom: 30px;
	margin-top: 30px;
	height: 50px;
	line-height: 50px;
	border: 1px solid #e8eaec;
}

.wrap .logGet .logD .el-button {
	width: 100%;
	height: 50px;
	font-size: 16px;
}

.title {
	font-family: "宋体";
	color: #ffffff;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	/* 使用css3的transform来实现 */
	font-size: 36px;
	height: 40px;
	width: 30%;
}

.copyright {
	font-family: "宋体";
	color: #ffffff;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	/* 使用css3的transform来实现 */
	height: 60px;
	width: 40%;
	text-align: center;
}

.login-box {
	width: 880px;
	height: 540px;
	background: #fff;
	box-shadow: 0 30px 50px 0 rgba(124,172,255,0.12);
	border-radius: 10px;
	font-size: 0;
	margin: 0 auto;
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	color: #1a263b;
}

.login-img {
	width: 400px;
	display: inline-block;
}
</style>
