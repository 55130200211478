import Vue from "vue";
import Vuex, { MutationTree, createLogger } from "vuex";
import modules from "./modules";
// import createLogger from 'vuex/dist/logger';
import StorageUtils from "@/common/StorageUtils";
import { deepAssign } from "@/utils/tools";

Vue.use(Vuex);
const isProduction = process.env.NODE_ENV === "production";
const debug = !isProduction;
// console.log("modules -->%o", modules);

const saveState = (store: any) => {
	// 当 store 初始化后调用
	let state = StorageUtils.loadStoreState();
	if (state) {
		let oldState = JSON.parse(JSON.stringify(store.state));
		let newState = deepAssign(oldState, state);
		store.replaceState(newState);
	}
	store.subscribe((mutation: MutationTree<any>, state: any) => {
		StorageUtils.saveStoreState(state);
	});
};
export default new Vuex.Store({
	modules,
	strict: debug,
	// store中使用的插件
	plugins: debug ? [createLogger(), saveState] : [saveState]
});
