import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import "./plugins";
import "@/style/index.scss"; // global css
// import SvgIcon from '@/components/SvgIcon'	// svg组件

Vue.config.productionTip = false;

// register globally
// Vue.component('SvgIcon', SvgIcon);

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");
