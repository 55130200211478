import Api from "@/api/apiAxios";
export default {
	getMerchantlist:(pageNo:Number,pageSize:Number,params:object)=> Api.postRequest(`/merchantInfo/queryPage/${pageNo}/${pageSize}`,params),     //查询商户
	getMerchantDetail:(merchantNo:Number)=> Api.getRequest(`/merchantInfo/queryMerInfo/${merchantNo}`),    										 //查询商户详情
	updateMerBaseInfo:(merchantNo:Number,params:object)=> Api.postRequest(`/merchantInfo/updateMerBaseInfo/${merchantNo}`,params),     			 //商户基本信息修改
	updateMerBusinessInfo:(merchantNo:Number,params:object)=> Api.postRequest(`/merchantInfo/updateMerBusinessInfo/${merchantNo}`,params),     	 //商户经营信息修改
	queryAgentRankInfo:(agentNo:Number)=> Api.postRequest(`/merchantInfo/queryAgentRankInfo/${agentNo}`),     	 								 //代理商层级信息
	getAuditMerchantlist:(pageNo:Number,pageSize:Number,params:object)=> Api.postRequest(`/merchantInfo/platform/queryPage/${pageNo}/${pageSize}`,params),     //查询平台进件商户
	ConfigApplyJobLog:(pageNo:Number,pageSize:Number,params:object)=> Api.postRequest(`/adjustTaskConfigApplyJobLog/queryPage/${pageNo}/${pageSize}`,params),     //商户调价记录
	JobLogExport:(params:object)=> Api.postRequest(`/adjustTaskConfigApplyJobLog/export`,params),     //商户调价记录导出
	adjustTaskConfigApply:{
		audit:(id:Number,params:{})=> Api.postRequest(`/adjustTaskConfigApply/audit/${id}`,params),
		detail:(id:Number)=> Api.postRequest(`/adjustTaskConfigApply/detail/${id}`),
		edit:(id:Number,params:{})=>Api.postRequest(`/adjustTaskConfigApply/edit/${id}`),
		export:(id:Number,params:{})=> Api.postRequest(`/adjustTaskConfigApply/export`),
		list:(pageNo: Number,pageSize: Number,params: {})=>Api.postRequest(`/adjustTaskConfigApply/list/${pageNo}/${pageSize}`,params),
		remove:(id:Number)=> Api.deleteRequest(`/adjustTaskConfigApply/remove/${id}`),
		save:(params:{})=> Api.putRequest(`/adjustTaskConfigApply/save`,params)
	}
};
