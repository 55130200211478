import Vue from 'vue'
import {VNode} from "vue";
import {DirectiveBinding} from "vue/types/options";
Vue.directive('numberInt', {
	bind: function (el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
		const element = el.getElementsByTagName('input')[0]
    	let lengthNo = binding.arg ? Number(binding.arg) : 2
		element.value = Number(element.value).toFixed(lengthNo)
		element.addEventListener('blur', function () {
			if (isNaN((element as any).value)) {
				(vnode as any).data.model.callback(0.00)
			} else if((element as any).value<0){
				(vnode as any).data.model.callback(0.00)
			} else {
				(vnode as any).data.model.callback(Number(element.value).toFixed(lengthNo))
			}
		})
	}
});
